import { useMemo } from "react";
import { CaseManagementDataGrid, CaseManagementDataGridProps } from "./CaseManagementDataGrid";
import { _case } from "@packages/case-management-queries";
import { SearchFilter } from "@packages/service-api";

export type CaseManagementSubscriptionsDataGridPageProps = Pick<
  CaseManagementDataGridProps,
  "rowActions"
>;

export function CaseManagementSubscriptionsDataGridPage({
  rowActions
}: CaseManagementSubscriptionsDataGridPageProps) {
  // TODO: This filter should come from user preferences.
  const filters = useMemo(() => {
    return {
      "caseStage.title": "Submitted"
    } as SearchFilter<_case>;
  }, []);

  const defaultVisibleColumns = useMemo(() => {
    return [
      "caseId",
      "site",
      "asset",
      "description",
      "initiatingAction",
      "event",
      "impact",
      "severity",
      "type",
      "openedBy",
      "openedDate",
      "status"
    ];
  }, []);

  return (
    <CaseManagementDataGrid
      filters={filters}
      defaultVisibleColumns={defaultVisibleColumns}
      rowActions={rowActions}
    />
  );
}
