import { _case } from "@packages/case-management-queries";
import {
  styled,
  MUIDrawer as Drawer,
  DrawerLayout,
  Tab,
  TabContext,
  TabList,
  TabPanel,
  Button,
  LabelValue,
  LabelValueGrid,
  Divider
} from "@packages/theme-mui-v5";
import { Fragment, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { CaseStageHistoriesTimeline } from "./CaseStageHistoriesTimeline";
import { CaseCommunicationsTimeline } from "./CaseCommunicationsTimeline";
import { CasePersonnel } from "./CasePersonnel";
import { formatDateTimeShort } from "@packages/core";

// TODO DrawerResizable sets atom width
export const casePreviewDrawerWidth = 320;

const DrawerStyled = styled(Drawer)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    position: "relative"
  },
  minWidth: "100%",
  [theme.breakpoints.up(700)]: {
    minWidth: 500
  },
  zIndex: 1500,
  "& > .MuiDrawer-paperAnchorRight": {
    borderRadius: 0
  },
  "& .MuiDrawer-paper": {
    [theme.breakpoints.down(700)]: {
      width: "100%"
    }
  }
})) as typeof Drawer;

export type CasePreviewDrawerProps = {
  case?: _case;
  open: boolean;
  onClose: () => void;

  /** In the context of the app, the persistent drawer will make room for the app bar in a `fixed` position. */
  appBarHeight?: number;
};

export function CasePreviewDrawer({
  case: _case,
  open,
  onClose,
  appBarHeight
}: CasePreviewDrawerProps) {
  const handleClose = () => onClose();
  const [tab, setTab] = useState<"details" | "posts" | "personnel" | "history" | "assetTags">(
    "details"
  );
  return (
    <DrawerStyled
      open={open}
      onClose={handleClose}
      anchor="right"
      variant="persistent"
      PaperProps={{
        style: {
          width: casePreviewDrawerWidth,
          top: appBarHeight,
          height: appBarHeight ? `calc(100vh - ${appBarHeight}px)` : undefined
        }
      }}
    >
      {_case ? (
        <DrawerLayout
          onClose={handleClose}
          title="Case Preview"
          content={
            <div>
              <TabContext value={tab}>
                <TabList
                  onChange={(e, newValue) => setTab(newValue)}
                  aria-label="Case Card Tabs"
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  <Tab label="Details" value="details" />
                  <Tab label="History" value="history" />
                  <Tab label="Posts" value="posts" />
                  <Tab label="Personnel" value="personnel" />
                  <Tab label="Site Asset Tags" value="caseSitesAssetTags" />
                </TabList>
                <TabPanel value="details" sx={{ p: 2 }}>
                  <LabelValueGrid>
                    <LabelValue
                      label="Severity"
                      value={_case.caseSeverityCategory?.titleShort || "Unknown"}
                    />
                    <LabelValue label="Stage" value={_case.caseStage?.title || "Unknown"} />
                    <LabelValue
                      label="Type Category"
                      value={_case.caseTypeCategory?.title || "Unknown"}
                    />
                    <LabelValue
                      label="Event Category"
                      value={_case.caseEventCategory?.title || "Unknown"}
                    />
                    <LabelValue label="Description" value={_case.description} />
                    <LabelValue label="Created By" value={_case.createdBy} />
                    <LabelValue label="Created At" value={formatDateTimeShort(_case.createdOn)} />
                    <LabelValue label="Modified At" value={formatDateTimeShort(_case.modifiedOn)} />
                  </LabelValueGrid>
                </TabPanel>
                <TabPanel value="history" sx={{ p: 0 }}>
                  <CaseStageHistoriesTimeline case={_case} />
                </TabPanel>
                <TabPanel value="posts" sx={{ p: 0 }}>
                  <CaseCommunicationsTimeline case={_case} />
                </TabPanel>
                <TabPanel value="personnel" sx={{ p: 2 }}>
                  <CasePersonnel case={_case} />
                </TabPanel>
                <TabPanel value="caseSitesAssetTags" sx={{ p: 2 }}>
                  <LabelValueGrid>
                    {_case.caseSitesAssetsTags.map((caseSiteAssetTag, index) => {
                      const isFirst = index === 0;
                      return (
                        <Fragment key={caseSiteAssetTag.id}>
                          {!isFirst && <Divider />}

                          <LabelValue
                            label="Site"
                            // @ts-expect-error relationships are not on the openapi spec type but they do exist
                            value={caseSiteAssetTag?.site || "N/A"}
                          />

                          <LabelValue
                            label="Asset"
                            // @ts-expect-error relationships are not on the openapi spec type but they do exist
                            value={caseSiteAssetTag?.asset || "N/A"}
                          />

                          <LabelValue
                            label="Tag"
                            // @ts-expect-error relationships are not on the openapi spec type but they do exist
                            value={caseSiteAssetTag?.tag || "N/A"}
                          />
                        </Fragment>
                      );
                    })}
                  </LabelValueGrid>
                </TabPanel>
              </TabContext>
            </div>
          }
          actions={
            <>
              <Button onClick={() => alert("not yet implemented")}>Pin</Button>
              <Button onClick={() => alert("not yet implemented")}>Follow</Button>
              <Button component={RouterLink} to={`/case/${_case.id}/edit`}>
                Edit
              </Button>
            </>
          }
        />
      ) : null}
    </DrawerStyled>
  );
}
