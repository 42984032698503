import { useMemo } from "react";
import { CaseManagementDataGrid, CaseManagementDataGridProps } from "./CaseManagementDataGrid";
import { _case } from "@packages/case-management-queries";
import { SearchFilter, useUserProfile } from "@packages/service-api";

export type CaseManagementAssignedToMeCasesDataGridPageProps = Pick<
  CaseManagementDataGridProps,
  "rowActions"
>;

export function CaseManagementAssignedToMeCasesDataGridPage({
  rowActions
}: CaseManagementAssignedToMeCasesDataGridPageProps) {
  const { email } = useUserProfile();
  const filters = useMemo(() => {
    return {
      "caseActionItems.assignedToUserId": email
    } as SearchFilter<_case>;
  }, [email]);

  const defaultVisibleColumns = useMemo(() => {
    return ["caseId", "description", "createdBy", "description", "status", "StartDate", "dueDate"];
  }, []);

  return (
    <CaseManagementDataGrid
      filters={filters}
      defaultVisibleColumns={defaultVisibleColumns}
      rowActions={rowActions}
    />
  );
}
