import { useMemo, useState } from "react";
import { CaseManagementDataGrid, CaseManagementDataGridProps } from "./CaseManagementDataGrid";
import { _case } from "@packages/case-management-queries";
import { CaseManagementSearchForm, CaseManagementSearchFormType } from "./CaseManagementSearchForm";
import { Box } from "@packages/theme-mui-v5";

export type CaseManagementDataGridPageProps = Pick<CaseManagementDataGridProps, "rowActions">;

export function CaseManagementSearchDataGridPage({ rowActions }: CaseManagementDataGridPageProps) {
  const [filters, setFilters] = useState<CaseManagementSearchFormType>();

  const defaultVisibleColumns = useMemo(() => {
    return [
      "caseId",
      "site",
      "asset",
      "description",
      "NROCFocusPerPlan",
      "alarmId",
      "alarmDate",
      "initiatingAction",
      "event",
      "impact",
      "severity",
      "type",
      "openedBy",
      "openedDate",
      "StartDate",
      "dueDate",
      "status"
    ];
  }, []);

  return (
    <Box>
      <CaseManagementSearchForm onSubmit={setFilters} />
      {!!Object.keys(filters || {}).length && (
        <CaseManagementDataGrid
          filters={filters}
          defaultVisibleColumns={defaultVisibleColumns}
          rowActions={rowActions}
        />
      )}
    </Box>
  );
}
