import { useMemo } from "react";
import { SearchFilter, useUserProfile } from "@packages/service-api";
import { CaseManagementDataGrid, CaseManagementDataGridProps } from "./CaseManagementDataGrid";
import { _case } from "@packages/case-management-queries";

export type CaseManagementValidationDataGridPageProps = Pick<
  CaseManagementDataGridProps,
  "rowActions"
>;

export function CaseManagementValidationDataGridPage({
  rowActions
}: CaseManagementValidationDataGridPageProps) {
  const { email } = useUserProfile();
  const filters = useMemo(() => {
    return {
      "caseValidators.caseValidatorUserId": email,
      "caseStage.title": "Validate"
    } as SearchFilter<_case>;
  }, [email]);

  const defaultVisibleColumns = useMemo(() => {
    return [
      "caseId",
      "site",
      "asset",
      "description",
      "initiatingAction",
      "event",
      "impact",
      "severity",
      "type",
      "openedBy",
      "openedDate",
      "status"
    ];
  }, []);

  return (
    <CaseManagementDataGrid
      filters={filters}
      defaultVisibleColumns={defaultVisibleColumns}
      rowActions={rowActions}
    />
  );
}
